<template>
<div>
      <div id="carouselExampleFade" class="carousel slide w-100" data-bs-ride="carousel" data-bs-interval="3000">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="https://picsum.photos/600/350" class="d-block w-100">
          </div>
          <div class="carousel-item">
            <img src="https://picsum.photos/600/350" class="d-block w-100">
          </div>
          <div class="carousel-item">
            <img src="https://picsum.photos/600/350" class="d-block w-100">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
        
    </div>
  <div class="d-flex e-pic px-3">
    <div class="col text-white p-0">{{ JOBS_TYPE[data.type_id] }}</div>
    <div class="col text-white px-0 text-r">{{ SALARYINDEX[data.salarys] }}/月</div>
  </div>
</div>
<div class="bg-white p-3">
  <div class="row border mt-2">
      <div class="col p-1 text-muted">
        工作地: {{ data.address }}
      </div>
      <div class="col text-r p-1 text-danger">
        推荐奖:500元/月
      </div>
  </div>
  <div class="mt-2">
      <h5>优 势</h5>
      <div class="border p-2">
        <span class="bg-97 mx-2" v-for="(tags, code) in data.tags" :key="code">{{ ADVANTAGE[tags]}}</span>
      </div>
  </div>
  
  <div class="mt-2">
    <h5>说明</h5>
    <div class="border py-1 px-2" v-html="data.content"></div>
  </div>
</div>
<JobNavbar :ids="route.params.id"></JobNavbar>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue"
import { useRoute } from 'vue-router'
import Axios from 'axios'
import { Store } from '@/bus'
import JobNavbar from "@/components/job_navbar.vue"
import { JOBS_TYPE, SALARYINDEX, ADVANTAGE, SETTLEMENT_TYPE } from '@/lib/data'
export default {
  name: "jobsInfo",
  components: {
    JobNavbar
  },
  setup() {
    const menu = Store()
    const route = useRoute()

    const data = ref({
      name: "97打工网",
      type_id: 0, //岗位类型 技工、普工--->jobs_type
      content: "<h4>其它说明</h4><i>这是一个很不错的工作，赶快宴请报名吧！！！</i><ol><li>hello world!</li><li>sdfasfdas</li></ol><blockquote><p>这是一个长引用，这是一个长引用。</p></blockquote>",
      salarys: 1, //综合工资
      tags: [0,2,4,5], //包吃包住、不穿无尘衣
      schooling: 0,
      address: "苏州市工业园区",
      settlement_id: "", //结算方式 --->staffs_settlement
      contact: "simon",
      contact_tel: 180180180888,
      companyImages: [
        '/imgs/19.jpg'
      ]
    })

    function getDate(id) {
      Axios.get('/jobs/info/' + id).then((response) => {
          if(response.data.code == 0) {
            data.value = response.data.data
          }
      })
    }
    onMounted(() => {
      document.title = data.value.name
      getDate(route.params.id)
      menu.hideMenu()
    })

    onBeforeUnmount(() => {
      menu.showMenu()
    })

    return {
      route,
      SETTLEMENT_TYPE,
      JOBS_TYPE,
      ADVANTAGE,
      SALARYINDEX,
      menu,
      data
    }
  }
}
</script>

<style scoped>
.e-pic {
  position: relative;
  margin-top: -40px;
}
.e-pic div {
  color: #fff;
  text-shadow: 1px 1px 3px #272727;
}
.e-top-content {
  width: 100%;
  display: block;
  clear: both;
  color: #adadad;
  line-height: 2em;
  z-index: 99999;
}
.e-top-content-left {
  float: left;
  margin-left: 20px;
}
.e-top-content-right {
  float: right;
  color: red;
  margin-right: 10px;
}
</style>