<template>
    <div class="e-nav">

      <div class="e-nav-left clearfix">
          
        <div class="e-nav-left-list">
          <a href="tel:180180180180">
            <i class="fa-regular fa-comments"></i>
            咨询
          </a>
          <!-- <div class="e-kefu">
            <a
              class=""
              href="https://121.121cc.cn/app/index.php?i=2&c=entry&toopenid=ojTue0Qww8OXJHk5ePLaRzJLFNlU&do=chat&m=cy163_customerservice"
            >
              <p class="layui-btn">
                <i class="layui-icon">&#xe606;</i>在线咨询
              </p>
              <br />
            </a>
            <a class="tel" tel="18020258666" href="javascript:;">
              <p class="layui-btn">
                <i class="layui-icon">&#xe63b;</i>电话咨询
              </p>
            </a>
          </div> -->
        </div> 

        <div class="e-nav-left-list">
          <a class="fenxiang" @click="showShare=true">
            <i class="fa-solid fa-share-nodes"></i>
            分享
          </a>
          <div class="e-fenxiang" v-if="showShare" @click="showShare=false">
            <div align="right" style="margin-right: 20px">
              <i class="fa-regular fa-hand-point-left fs-1 text-info"></i>
            </div>
            赶快点击右上角的三(...)发给您的小伙伴们吧！
          </div>
        </div>
        <div class="e-nav-left-list">
          <a class="shoucang" @click="Collection">
            <i class="fa-solid fa-building-columns"></i>
            收藏
          </a>
        </div>

      </div>

      <div class="e-nav-right"><a @click="apply">申 请</a></div>
    </div>
    <jmodal v-if="showModal" @close="showModal = false">
      <template v-slot:title>
          {{ ModalTitle }}
      </template>
      <template v-if="showSendApply" v-slot:content>
        <h4>请选择</h4>
        <form>
            <div v-for="(value,index) in settlement" :key="index" class="m-2">
              <input type="radio" name="radio" :id="'st' + index" :value="value.id" v-model="checkType">
              <label :for="'st' + index">{{ SETTLEMENT_TYPE[value.type] }}</label>
              <div class="ms-3">{{value.description}}</div>
            </div>
        </form>
        <div class="text-center mt-3">
          <button class="btn btn-outline-success btn-sm" @click="sendApply">提 交</button>
        </div>
      </template>
    </jmodal>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import { SETTLEMENT_TYPE } from '@/lib/data'
import Axios from 'axios'
const props = defineProps({
  ids: null
})
const showModal = ref(false)
const ModalTitle = ref('')
const checkType = ref()
const settlement = ref([
  {id: 1, type: 0, description: 'dfasfdsaf'},
  {id: 2, type: 1, description: 'dddddddddd'}
]) //职位可执行的结算方式
function apply() {
  getSettlement()
    showModal.value = !showModal.value
}
function getSettlement(){
    // Axios.get('/api/v1/staff_settlement/' + props.ids).then((response) => {
    //     if(response.data.code == 0) {
    //         settlement.value = response.data.data
    //     }
    // })
}

//分享
const showShare = ref(false)
//收藏
function Collection() {
  alert('收藏成功')
}

//申请职位
const showSendApply = ref(false)
function sendApply() {
  ModalTitle.value = '报名选项'
  showModal.value = false
  Axios.post('http://localhost:5000/signups',
  {type: checkType.value, uid: 1001, jobs_id: props.ids, resume_id: 101, type_id: 1})
  .then((Response) => {
    if(Response.data.code == 0) {
      alert('申请成功')
    }
  })
}

</script>

<style>

    .e-nav{
        position:fixed;
        bottom:0;
        right:0;
        z-index: 999;
        width:100%;
        background:#fff;
        border: 0.6px solid #bebebe;
    }
    .e-nav-left{
        float:left;
        height:2.45rem;
        width:55%;
        /*background:#fff;*/
        z-index: 1000;
        text-align: right;
        
    }
    .e-nav-left-list{
        float:right;
        width:33%;
        text-align:center;
        z-index: 1010;
        height:2.4rem;
        padding: 5px 0 0;
        color:#8e8e8e;
    }
    .e-nav-left-list a{
        height:2.4rem;
        padding: 5px 0 0;
        color:#8e8e8e;
    }
    .e-nav-left-list a i{
        height:2.4rem;
        padding: 5px 0 0;
        color:#8e8e8e;
    }
    .e-nav-right{
        float:right;
        height: 2.45rem;
        background:#009688;
        z-index: 1000;
        width:45%;
        color: #FFF;
        font-size: 1.5em;
        text-align:center;
        line-height:1.5em;
    }
    .fui-navbar .nav-item {
        position: relative;
        display: table-cell;
        height: 2.45rem;
        text-align: center;
        vertical-align: middle;
        width: 1%;
        color: #999;
    }
     .like{
        font-size: 0.8em;
    }
        .e-kefu{
        position:fixed;
        top:50%;
        left:50%;
        z-index: 10;
        width:0;
        height: 0;
        background:#FFF;
        border:2px solid #adadad;
        opacity:0;
        text-align: center;
        padding: 15px;
        
    }
    .e-kefu p {
        position: relative;
        margin: 5px 0 10px;
    }
    .e-fenxiang{
        position:fixed;
        top:0;
        right:0;
        z-index: 9999;
        width:100%;
        height: 100%;
        background:#000;
        border:2px solid #adadad;
        opacity:.5;
        text-align: center;
        padding:20px 15px 15px 50px;
        font-size: 1.5em;
        color: #fff;
    }
    .e-fenxiang i {
      transform: rotate(120deg);
    }
</style>